import React from "react"

import styled from "styled-components"
import ArticleCardSmall from "./ArticleCardSmall"

const Container = styled.div`
  padding-top: 20px;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
`
const Title = styled.p`
  color: ${({ theme }) => theme.darkblue};
  font-size: 24px;
  font-weight: 600;
`

export default function ArticleCardsContainer({ articles }) {
  return (
    <Container>
      <Title>Potrebbe interessarti anche:</Title>
      <CardsContainer>
        {articles.map(c => (
          <ArticleCardSmall {...c} />
        ))}
      </CardsContainer>
    </Container>
  )
}
