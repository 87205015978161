import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import placeholder from "../../images/arti-placeholder.jpeg"
import { Markup } from "interweave"
const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  cursor: pointer;
  border-radius: 12px;
  background: white;
  box-shadow: 5px 5px 20px #efefef;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  @media ${device.tablet} {
    margin-bottom: 5px;

    border-radius: 0;
  }
`

const Content = styled.p``

const Title = styled.p`
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 18px;
  }
`
const Image = styled.div`
  width: 100%;
  height: 200px;
  margin: 10px;
  margin-left: 0px;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  margin-bottom: 0;
  margin-top: auto;
`
const Author = styled.p`
  margin-right: 30px;
  color: ${props => props.theme.blue};
  margin-bottom: 0;
`

const Date = styled.p`
  color: #8e8e8e;
  margin-bottom: 0;
`

const TrattamentoArtiCard = ({
  titolo,
  chirurgo,
  descrizione,
  url_foto,
  data,
  ...restProps
}) => {
  const content = descrizione.length > 430 ? descrizione.slice(0, 430) + "... Continua a leggere" : descrizione;
  return (
    <Container {...restProps}>
      <Row>
        <Col sm={12} md={4}>
          <Image img={url_foto || placeholder} />
        </Col>
        <StyledCol sm={12} md={8}>
          <Title>{titolo}</Title>
          <Content><Markup content={content}/></Content>
          <Footer>
            <Author>{chirurgo}</Author>
            <Date>{moment(data).format("DD MMMM YYYY")}</Date>
          </Footer>
        </StyledCol>
      </Row>
    </Container>
  )
}

export default TrattamentoArtiCard
