import React, { useRef, useState } from "react"
import HeroSection from "../HeroSection/HeroSection"
import baseImg from "../../images/hero.png"
import { Button } from "../PreForm/styles/prefo"
import { Trattamenti } from "../../constants/trattamenti"

import { Autocomplete } from "@material-ui/lab"
import ReviewsModal from "../ReviewsModal/ReviewsModal"
import ModalButton from "../ModalButton/ModalButton"
import { colors } from "../../constants/Colors"

export default function ArticoliHeroContainer({
  value,
  setValue,
  trattamenti,
  onSubmit,
}) {
  return (
    <HeroSection img={baseImg}>
      <HeroSection.Feature
        style={{ fontSize: 30, marginTop: "auto", padding: 0 }}
        center
      >
        I NOSTRI ARTICOLI
      </HeroSection.Feature>
      <HeroSection.BottomOverlay>
        <h2>Cerca articoli sul tuo trattamento</h2>
        <HeroSection.SearchContainer title="trattamenti">
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            freeSolo
            options={trattamenti}
            getOptionLabel={option => option.trattamento}
            renderInput={params => (
              <HeroSection.SearchLeft
                title="trattamenti"
                {...params}
                label="Cerca un trattamento"
              />
            )}
          />
          <HeroSection.SearchButton
            onClick={onSubmit}
            title={"trattamenti"}
            img={require("../../images/Search.png")}
          ></HeroSection.SearchButton>
        </HeroSection.SearchContainer>
      </HeroSection.BottomOverlay>
    </HeroSection>
  )
}
