import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import placeholder from "../../images/arti-placeholder.jpeg"
const Container = styled.div`
  width: 30%;
  margin-left: 3%;
  border-radius: 12px;
  background: white;
  float: left;

  box-shadow: 5px 5px 20px #efefef;
  @media ${device.tablet} {
    width: 100%;
  }
`

const Image = styled.div`
  background: url(${props => props.img}) center center/cover no-repeat;
  height: 220px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
`
const Author = styled.div`
  color: ${props => props.theme.lightblue};
  margin-bottom: 5px;
`

const Body = styled.div``

const Date = styled.div`
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 500;
`
const Content = styled.p``

const Inner = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export default function ArticleCardSmall({ descrizione, url_foto }) {
  return (
    <Container>
      <Image img={url_foto || placeholder} />
      <Inner>
        <Title>Mastoplastica Riduttiva</Title>
        <Author>Dott. Angelo Serraglia</Author>
        <Content>{descrizione.substring(0, 50)}</Content>
        <Date>20 gennaio 2021</Date>
      </Inner>
    </Container>
  )
}
