import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Col, Container as BottContainer, Row } from "react-bootstrap"
import SuggestedCol from "../SuggestedCol/SuggestedCol"

import { useDispatch, useSelector } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import NoResults from "../NoResults/NoResults"
import useChirurghiHome from "../../hooks/useChiurghiHome"
import TrattamentoReviewsCard from "../ReviewsCard/ReviewsCard"
import { device } from "../../constants/breakpoints"
import LoadingViewSm from "../LoadingViewSm/LoadingViewSm"
import { navigateToArticolo } from "../../functions/navigateToArticoli"

import {
  fetchMoreArticlesAsync,
  selectArticles,
  selectArticlesCurrentPage,
  selectArticlesLoading,
  selectArticlesLoadingMore,
  selectArticlesPagesTotal,
} from "../../redux/articlesSlice"
import ArticleCardsContainer from "../ArticleCardsContainer/ArticleCardsContainer"
import ArticleCardSmall from "../ArticleCardsContainer/ArticleCardSmall"
import TrattamentoArtiCard from "../TrattamentoArtiContainer/TrattamentoArtiCard"

const Wrapper = styled.div`
  background: white;
  padding-bottom: 150px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`

const Container = styled(BottContainer)`
  padding: 20px;

  padding-left: 5%;
  padding-right: 5%;
  @media ${device.tablet} {
    padding: 0px;
  }
`

const Touchable = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.blue};
  font-weight: 500;
`

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  align-content: center;
  min-height: 1400px;
  padding-bottom: 80px;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  @media ${device.tablet} {
    padding-top: 100px;
  }
`

export default function ArticoloContainer({ title, trattamentoId }) {
  const articles = useSelector(selectArticles)
  const loading = useSelector(selectArticlesLoading)
  const currentPage = useSelector(selectArticlesCurrentPage)
  const pagesTotal = useSelector(selectArticlesPagesTotal)
  const loadingMore = useSelector(selectArticlesLoadingMore)
  const doctors = useChirurghiHome()
  const [endReached, setEndReached] = useState(false)
  const containerRef = useRef()
  const dispatch = useDispatch()
  const isBottom = el => {
    return el ? el.getBoundingClientRect().bottom <= window.innerHeight : false
  }

  const trackScrolling = () => {
    if (isBottom(containerRef?.current)) {
      setEndReached(true)
    }
  }

  useEffect(() => {
    if (endReached) {
      if (currentPage < pagesTotal) {
        dispatch(fetchMoreArticlesAsync(trattamentoId, currentPage + 1))
        setTimeout(() => setEndReached(false), 1000)
      }
    }
  }, [endReached])

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling)

    return () => document.removeEventListener("scroll", trackScrolling)
  }, [])

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  } else if (!articles.length) {
    return (
      <LoadingContainer>
        <NoResults />
      </LoadingContainer>
    )
  }
  return (
    <Wrapper>
      <Container ref={containerRef} fluid>
        <Row gx-0>
          <Col style={{ paddingRight: "2%" }} sm={12} md={8} xl={9} xxl={10}>
            {articles.map(r => (
              <TrattamentoArtiCard
                onClick={() => navigateToArticolo({ articolo: r })}
                {...r}
              />
            ))}
          </Col>
          <Col sm={12} md={4} xl={3} xxl={2}>
            <SuggestedCol doctors={doctors} title={title} />
          </Col>
        </Row>
      </Container>
      {loadingMore && <LoadingViewSm />}
    </Wrapper>
  )
}
