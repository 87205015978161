import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import EsperienzeHeroContainer from "../components/EsperienzeHeroContainer/EsperienzeHeroContainer"
import { fetchTrattamentiAsync, selectTrattamenti } from "../redux/searchSlice"
import { useDispatch, useSelector } from "react-redux"
import { fetchReviewsAsync } from "../redux/reviewsSlice"
import EsperienzeContainer from "../components/EsperienzeContainer/EsperienzeContainer"
import ArticoliHeroContainer from "../components/ArticoliHeroContainer/ArticoliHeroContainer"
import ArticoloContainer from "../components/ArticoliContainer/ArticoloContainer"
import { fetchArticlesAsync } from "../redux/articlesSlice"

const ArticoliPage = ({ location }) => {
  const crumbs = [
    {
      crumbLabel: "Articoli",
      pathname: location.pathname,
    },
  ]

  const dispatch = useDispatch()
  const [trattamento, setTrattamento] = useState(null)
  const trattamenti = useSelector(selectTrattamenti)

  useEffect(() => {
    dispatch(fetchTrattamentiAsync())
    dispatch(fetchArticlesAsync(trattamento ? trattamento.id_sys : ""))
  }, [])

  const onSubmit = () => {
    dispatch(fetchArticlesAsync(trattamento ? trattamento.id_sys : ""))
  }

  return (
    <Layout>
      <SEO title="Articoli" />
      <BreadCrumbs crumbs={crumbs} />
      <ArticoliHeroContainer
        onSubmit={onSubmit}
        trattamenti={trattamenti}
        value={trattamento}
        setValue={setTrattamento}
      />
      <ArticoloContainer
        trattamentoId={trattamento ? trattamento.id_sys : ""}
      />
    </Layout>
  )
}

export default ArticoliPage
